import React, { useState, useEffect } from "react";
import {
  ChakraProvider,
  extendTheme,
  Text,
  Input,
  Button,
  Spinner,
  Link,
  Box,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Spacer,
  Flex,
} from "@chakra-ui/react";

const customTheme = extendTheme({
  styles: {
    global: {
      body: {
        bg: "#F8F8F8",
      },
    },
  },
});

function App() {
  const [inputValue, setInputValue] = useState("");
  const [displayText, setDisplayText] = useState("");
  const [wc, setWC] = useState("");
  const [transcript, setTranscript] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [isCopiedTrans, setIsCopiedTrans] = useState(false);
  const [isCopiedHigh, setIsCopiedHigh] = useState(false);
  const [clipLink, setClipLink] = useState("");
  const [clipLoading, setclipLoading] = useState(false);
  const [selectedText, setSelectedText] = useState("");

  // Existing useDisclosure for "How to Use" modal
  const { isOpen, onOpen, onClose } = useDisclosure();

  // New useDisclosure for the Alert modal
  const {
    isOpen: isAlertOpen,
    onOpen: onAlertOpen,
    onClose: onAlertClose,
  } = useDisclosure();

  // Open the Alert modal on component mount
  useEffect(() => {
    onAlertOpen();
  }, [onAlertOpen]);

  const handleInput = (event) => {
    setInputValue(event.target.value);
  };

  const handleTextSelection = () => {
    const selection = window.getSelection().toString();
    setSelectedText(selection);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("Input Value:", inputValue);
    setIsLoading(true);
    try {
      //https://web-production-762ec.up.railway.app/https://tldwback-production.up.railway.app/link
      console.log("hey");
      const response = await fetch(
        "https://vishalshenoy--tldw-app-fastapi-modal-app.modal.run/link",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          //input_string: inputValue
          body: JSON.stringify({ input_string: inputValue }),
        }
      );
      console.log("done");

      const data = await response.json();
      console.log(data);
      setDisplayText(data.processed_string);
      setWC(data.wc);
      setTranscript(data.original_string);
    } catch (error) {
      console.error("Error posting data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(displayText);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  const handleTranscript = () => {
    navigator.clipboard.writeText(transcript);
    setIsCopiedTrans(true);
    setTimeout(() => {
      setIsCopiedTrans(false);
    }, 1000);
  };

  const handleCopyHighlightedText = async (event) => {
    event.preventDefault();
    //setclipLoading(true);
    const highlightedText = window.getSelection().toString();
    if (highlightedText) {
      //https://web-production-762ec.up.railway.app/https://tldwback-production.up.railway.app/embed
      const response = await fetch(
        "https://vishalshenoy--tldw-app-fastapi-modal-app.modal.run/embed",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ highlighted_text: highlightedText }), // Send the highlighted text
        }
      );

      const data = await response.json();
      console.log(data.highlighted_text);
      setClipLink(data.highlighted_text);
      window.open(data.highlighted_text, "_blank");
      //setclipLoading(false);
    }
  };

  // Determine if any modal is open
  const isAnyModalOpen = isAlertOpen || isOpen;

  return (
    <ChakraProvider theme={customTheme}>
      {/* Alert Modal */}
      <Modal isOpen={isAlertOpen} onClose={onAlertClose} isCentered>
        <ModalOverlay backdropFilter="blur(4px)" bg="rgba(0, 0, 0, 0.4)" />
        <ModalContent>
          <ModalHeader>An Update 👀</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Unfortunately, the YouTube API that powers video downloads on <strong>tldw</strong> has been discontinued, so this project is currently not functional. A fix is on the way!
            </Text>
            <br></br>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Apply blur to the main content when any modal is open */}
      <Box
        filter={isAnyModalOpen ? "blur(4px)" : "none"}
        transition="filter 0.3s ease"
        minHeight="100vh"
      >
        {/* Existing Content */}
        <Flex p={4} align="center" justify="center">
          <Text
            color="#2d3436"
            mt="50px"
            fontSize="7xl"
            fontFamily="Arial"
            fontWeight="bold"
            mr={2}
          >
            tldw
          </Text>
          <Text
            fontSize="xl"
            color="#6c5ce7"
            lineHeight="1.1"
            fontWeight="bold"
          >
            too lazy <br /> didn't watch
          </Text>
        </Flex>

        <Box
          p={3}
          borderWidth="0px"
          mx="auto"
          my="0px"
          width={{ base: "95%", md: "70%", lg: "70%" }}
        >
          <form onSubmit={handleSubmit}>
            <Input
              type="text"
              placeholder="Enter a YouTube video link..."
              onChange={handleInput}
              value={inputValue}
              borderWidth="3px"
            />
            <Center>
              <Button
                bg="#6c5ce7"
                color="#F8F8F8"
                type="submit"
                disabled={isLoading}
                my="10px"
              >
                {isLoading ? <Spinner size="sm" color="white" /> : "process"}
              </Button>
            </Center>
          </form>
        </Box>
        {!isLoading && displayText !== "" && (
          <Box
            p={4}
            border="3px solid #E2E8F0"
            borderRadius="md"
            mx="auto"
            my="0px"
            width={{ base: "95%", md: "70%", lg: "70%" }}
          >
            <Text
              fontSize="xl"
              fontWeight="bold"
              color="#6c5ce7"
              lineHeight="1.1"
            >
              Summarized Notes ({wc}% condensed)
            </Text>
            <p
              color="#6c5ce7"
              style={{ marginTop: "10px" }}
              backgroundColor={selectedText ? "purple.200" : "transparent"}
            >
              {displayText}
            </p>
            <br />
            <Flex>
              <Button
                bg="#6c5ce7"
                color="#F8F8F8"
                size="sm"
                mr="5px"
                onClick={handleCopy}
                disabled={isCopied}
              >
                {isCopied ? "copied!" : "copy notes"}
              </Button>
              <Button
                bg="#6c5ce7"
                color="#F8F8F8"
                size="sm"
                mr="5px"
                onClick={handleTranscript}
                disabled={isCopiedTrans}
              >
                {isCopiedTrans ? "copied!" : "copy transcript"}
              </Button>
              <Button
                bg="#6c5ce7"
                color="#F8F8F8"
                size="sm"
                mr="5px"
                onClick={handleCopyHighlightedText}
                disabled={isCopiedHigh}
              >
                {clipLoading ? (
                  <Spinner size="sm" color="white" />
                ) : (
                  "find clip"
                )}
              </Button>
              {inputValue && (
                <Button
                  as="a"
                  href={inputValue}
                  bg="#d63031"
                  color="#F8F8F8"
                  size="sm"
                  target="_blank"
                  rel="noopener noreferrer"
                  fontWeight="bold"
                >
                  YouTube
                </Button>
              )}
              <Spacer />
              <Button
                bg="#2d3436"
                color="#F8F8F8"
                onClick={onOpen}
                size="sm"
                rounded="full"
              >
                &#9432;
              </Button>
            </Flex>
          </Box>
        )}
        <br />

        {/* "How to Use" Modal */}
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay backdropFilter="blur(4px)" bg="rgba(0, 0, 0, 0.4)" />
          <ModalContent>
            <ModalHeader>How to Use</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text as="span" fontWeight="bold" color="#6c5ce7">
                copy notes
              </Text>
              : copies the summarized notes about the video to your clipboard{" "}
              <br />
              <Text as="span" fontWeight="bold" color="#6c5ce7">
                copy transcript
              </Text>
              : copies the entire video transcript to your clipboard <br />
              <Text as="span" fontWeight="bold" color="#6c5ce7">
                find clip
              </Text>
              : highlight a part of the notes and click this button. opens a
              timestamped YouTube link about your highlighted selection
              <br />
              <Text as="span" fontWeight="bold" color="#d63031">
                YouTube
              </Text>
              : opens the YouTube link in a new tab
            </ModalBody>
            <ModalFooter>
              <Link href="https://vishalshenoy.com/" isExternal>
                <Text fontSize="xs" fontFamily="Arial" textAlign="center">
                  built by vishal
                </Text>
              </Link>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </ChakraProvider>
  );
}

export default App;
